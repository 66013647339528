import {
  Button,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputRef,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../config/constants";
import { ICard } from "../../../../models/benchmark/shared/Card";
import { ITable } from "../../../../models/benchmark/shared/Table";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Card from "../../shared/card/Card";
import ScreenTable from "../../shared/table/Table";
import styles from "./PeriodicCleaning.module.scss";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  timeSchedule: string;
  basic: string;
  optimal: string;
  premium: string;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface FloorData {
  floorId: number;
  floorName: string;
  floorData: FloorProps[];
}

interface FloorProps {
  key: React.Key;
  specification: string;
  costPerUnit: string | number;
  secondsPerUnit: string | number;
  units: string | number;
  basic: string | number;
  optimal: string | number;
  premium: string | number;
  custom: string | number;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const PeriodicCleaning = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);
  const [compareActive, setCompareActive] = useState<boolean>(true);
  const [detailsActive, setDetailsActive] = useState<boolean>(false);
  const [selectedFloor, setSelectedFloor] = useState<number>(1);
  const [activeOption, setActiveOption] = useState<string>("Premium");
  const [cardData, setCardData] = useState<ICard[]>();
  const [tablesData, setTablesData] = useState<ITable[]>();
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const [carpetCleaningFrequency, setCarpetCleaningFrequency] = useState("monthly");
  const [hardFloorCleaningFrequency, setHardFloorCleaningFrequency] = useState("monthly");
  const [carpetCleaningPricePerUnit, setCarpetCleaningPricePerUnit] = useState(1.25);
  const [hardFloorCleaningPricePerUnit, setHardFloorCleaningPricePerUnit] = useState(1.5);
  const [doorPricePerHour, setDoorPricePerHour] = useState(100);
  const [panelPricePerHour, setPanelPricePerHour] = useState(100);
  const [dustingPricePerHour, setDustingPricePerHour] = useState(100);
  
  const [floorData, setFloorData] = useState<FloorData[]>([
    {
      floorId: 1,
      floorName: "1st Floor",
      floorData: [
        {
          key: 1,
          specification: "Glazing",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 2,
          specification: "Coffee/canteen",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 3,
          specification: "Carpet cleaning",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 4,
          specification: "Kitchen cupboard",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 5,
          specification: "Mail room",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
      ],
    },
    {
      floorId: 2,
      floorName: "2nd Floor",
      floorData: [
        {
          key: 1,
          specification: "Glazing",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 2,
          specification: "Coffee/canteen",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 3,
          specification: "Carpet cleaning",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 4,
          specification: "Kitchen cupboard",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 5,
          specification: "Mail room",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
      ],
    },
    {
      floorId: 3,
      floorName: "3rd Floor",
      floorData: [
        {
          key: 1,
          specification: "Glazing",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 2,
          specification: "Coffee/canteen",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 3,
          specification: "Carpet cleaning",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 4,
          specification: "Kitchen cupboard",
          costPerUnit: "£ 25.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
        {
          key: 5,
          specification: "Mail room",
          costPerUnit: "£ 20.0",
          secondsPerUnit: "115.0 s",
          units: 159,
          basic: "£ 182.85",
          optimal: "£ 2437.77",
          premium: "£ 8533.06",
          custom: "£ 7533.06",
        },
      ],
    },
  ]);

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark) {
      const screenData = benchmark.screens?.find(
        (scr) => scr.title === ROUTES.benchmark.periodicCleaning.name
      );
      if (screenData?.dashboards) {
        const dashboard = screenData.dashboards[0];
        dashboard?.cards && setCardData(dashboard.cards);
        dashboard?.tables && setTablesData(dashboard.tables);
      }
    }
  }, [dispatched, benchmark]);

  const defaultPeriodicCleaningColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: "10%",
    },
    {
      title: "Cost per unit",
      dataIndex: "costPerUnit",
      key: "costPerUnit",
      width: "10%",
      editable: true,
    },
    {
      title: "Seconds per unit",
      dataIndex: "secondsPerUnit",
      key: "secondsPerUnit",
      width: "10%",
      editable: true,
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "uits",
      width: "10%",
      editable: true,
    },
    {
      title: "Basic",
      dataIndex: "basic",
      key: "basic",
      width: "15%",
      className: activeOption === "basic" ? styles.activeBasic : "",
    },
    {
      title: "Optimal",
      dataIndex: "optimal",
      key: "optimal",
      width: "15%",
      className: activeOption === "optimal" ? styles.activeOptimal : "",
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      width: "15%",
      className: activeOption === "premium" ? styles.activePremium : "",
    },
    {
      title: "Custom",
      dataIndex: "custom",
      key: "custom",
      width: "15%",
      className: activeOption === "custom" ? styles.activeCustom : "",
    },
  ];

  const columns = defaultPeriodicCleaningColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: FloorData) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (floorId: number, row: FloorProps) => {
    const newFloorData = [...floorData].find(
      (floor) => floor.floorId === floorId
    )?.floorData;
    if (newFloorData) {
      const rowIndex = newFloorData.findIndex((item) => row.key === item.key);
      const rowItem = newFloorData[rowIndex];
      newFloorData.splice(rowIndex, 1, {
        ...rowItem,
        ...row,
      });
      //TODO Update the floor data
      // setFloorData(...floorData, newFloorData);
    }
  };
  
  

  const onFloorSelect = (value: number) => {
    setSelectedFloor(value);
  };

  const onCardClick = (cardId: string) => {
    setActiveOption(cardId);
    setShowDetailsDrawer(true);
  };

  const onClose = () => {
    setShowDetailsDrawer(false);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  
  const calculatePeriodicsPrice = () => {
    let carpetFrequency = 12;
    let hardfloorFrequency = 12;
    
    switch (carpetCleaningFrequency) {
      case "daily":
        carpetFrequency = 5 * 4 * 12;
        break;
      case "weekly":
        carpetFrequency = 4 * 12;
        break;
      case "biweekly":
        carpetFrequency = 2 * 12;
        break;
      case "monthly":
        carpetFrequency = 12;
        break;
      case "quarterly":
        carpetFrequency = 4;
        break;
      case "biannually":
        carpetFrequency = 2;
        break;
      case "annually":
        carpetFrequency = 1;
        break;
    }

    switch (hardFloorCleaningFrequency) {
      case "daily":
        hardfloorFrequency = 5 * 4 * 12;
        break;
      case "weekly":
        hardfloorFrequency = 4 * 12;
        break;
      case "biweekly":
        hardfloorFrequency = 2 * 12;
        break;
      case "monthly":
        hardfloorFrequency = 12;
        break;
      case "quarterly":
        hardfloorFrequency = 4;
        break;
      case "biannually":
        hardfloorFrequency = 2;
        break;
      case "annually":
        hardfloorFrequency = 1;
        break;
    }
    
    return (4950.22 * carpetFrequency * carpetCleaningPricePerUnit) + (1190.11 * hardfloorFrequency * hardFloorCleaningPricePerUnit) + (doorPricePerHour ?? 0) * 12 * 2.6 + (panelPricePerHour ?? 0) * 12 * 4.651 + (dustingPricePerHour ?? 0) * 4 * 21.5
  }
  
  return (
    <>
      <div className={styles.mobilePeriodicClening}>
        {pending && (
          <Spin
            style={{
              display: "inline-flex",
              height: "calc(50vh - 64px)",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          />
        )}
        <div className={styles.headContainer}>
          {cardData?.some((card) => card.title === "Custom") && (
            <div style={{ display: "flex", gap: "10px" }}>
              <label>Compare with custom benchmark</label>
              <Switch
                defaultChecked={true}
                onChange={() => setCompareActive(!compareActive)}
              />
            </div>
          )}
          {/* <div>
                <label>Show details</label>
                <Switch
                  defaultChecked={false}
                  onChange={() => setDetailsActive(!detailsActive)}
                />
              </div> */}
        </div>
        {cardData &&
          cardData.map((card, index) => (
            <Card
              key={index}
              data={card}
              isActive={card.title === activeOption}
              compareActive={compareActive}
              handleCardActivate={onCardClick}
              periodicsPrice={calculatePeriodicsPrice()}
            />
          ))}
        <Drawer
          title="Details"
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={showDetailsDrawer}
          height="600px"
          extra={
            <Space size="middle">
              <Button
                type="primary"
                // danger
                icon={<CloseOutlined />}
                onClick={onClose}
              ></Button>
            </Space>
          }
        >
          {tablesData &&
            tablesData.map((table, index) => {
              return (
                <div key={index} className={styles.bodyContainer}>
                  <ScreenTable
                    key={index}
                    title={table.title}
                    headers={
                      compareActive &&
                      cardData?.some((card) => card.title === "Custom")
                        ? table.headers
                        : table.headers?.filter((col) => col.name !== "Custom")
                    }
                    rows={table.rows ?? []}
                    activeOption={activeOption}
                    buildingId={buildingId}
                    doorPricePerHour={doorPricePerHour}
                    setDoorPricePerHour={setDoorPricePerHour}
                    panelPricePerHour={panelPricePerHour}
                    setPanelPricePerHour={setPanelPricePerHour}
                    dustingPricePerHour={dustingPricePerHour}
                    setDustingPricePerHour={setDustingPricePerHour}
                    carpetCleaningFrequency={carpetCleaningFrequency}
                    setCarpetCleaningFrequency={setCarpetCleaningFrequency}
                    hardFloorCleaningFrequency={hardFloorCleaningFrequency}
                    setHardFloorCleaningFrequency={setHardFloorCleaningFrequency}
                    carpetCleaningPricePerUnit={carpetCleaningPricePerUnit}
                    setCarpetCleaningPricePerUnit={setCarpetCleaningPricePerUnit}
                    hardFloorCleaningPricePerUnit={hardFloorCleaningPricePerUnit}
                    setHardFloorCleaningPricePerUnit={setHardFloorCleaningPricePerUnit}
                  />
                </div>
              );
            })}
        </Drawer>
      </div>
      <div className={styles.container}>
        <div className={styles.headContainer}>
          {cardData?.some((card) => card.title === "Custom") && (
            <div>
              <label>Compare with custom benchmark</label>
              <Switch
                defaultChecked={true}
                onChange={() => setCompareActive(!compareActive)}
              />
            </div>
          )}
          <div>
            <label>Show details</label>
            <Switch
              defaultChecked={false}
              onChange={() => setDetailsActive(!detailsActive)}
            />
          </div>
        </div>
        <div className={styles.bodyContainer}>
          {cardData &&
            cardData.map((card, index) => (
              <Card
                key={index}
                data={card}
                isActive={card.title === activeOption}
                compareActive={compareActive}
                handleCardActivate={setActiveOption}
                periodicsPrice={calculatePeriodicsPrice()}
              />
            ))}
        </div>
        <Divider style={{ margin: "16px 0" }} />
        {detailsActive && (
          <>
            {/*<h2>Floor breakdown</h2>*/}
            {/*<Select*/}
            {/*  options={floorData.map((x: any) => ({*/}
            {/*    value: x.floorId,*/}
            {/*    label: x.floorName,*/}
            {/*  }))}*/}
            {/*  onChange={onFloorSelect}*/}
            {/*  value={selectedFloor}*/}
            {/*></Select>*/}
            {tablesData &&
              tablesData.map((table, index) => {
                return (
                  <div key={index} className={styles.bodyContainer} style={{display: 'flex'}}>
                    
                    <ScreenTable
                      key={index}
                      title={table.title}
                      headers={
                        compareActive &&
                        cardData?.some((card) => card.title === "Custom")
                          ? table.headers
                          : table.headers?.filter(
                              (col) => col.name !== "Custom"
                            )
                      }
                      rows={table.rows ?? []}
                      activeOption={activeOption}
                      buildingId={buildingId}
                      doorPricePerHour={doorPricePerHour}
                      setDoorPricePerHour={setDoorPricePerHour}
                      panelPricePerHour={panelPricePerHour}
                      setPanelPricePerHour={setPanelPricePerHour}
                      dustingPricePerHour={dustingPricePerHour}
                      setDustingPricePerHour={setDustingPricePerHour}
                      carpetCleaningFrequency={carpetCleaningFrequency}
                      setCarpetCleaningFrequency={setCarpetCleaningFrequency}
                      hardFloorCleaningFrequency={hardFloorCleaningFrequency}
                      setHardFloorCleaningFrequency={setHardFloorCleaningFrequency}
                      carpetCleaningPricePerUnit={carpetCleaningPricePerUnit}
                      setCarpetCleaningPricePerUnit={setCarpetCleaningPricePerUnit}
                      hardFloorCleaningPricePerUnit={hardFloorCleaningPricePerUnit}
                      setHardFloorCleaningPricePerUnit={setHardFloorCleaningPricePerUnit}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default PeriodicCleaning;

const FloorTable = ({
  floorData,
  columns,
  components,
  activeFloor,
}: {
  floorData: FloorData;
  columns: any[];
  components: any;
  activeFloor: any;
}) => {
  return (
    <div
      className={styles.tableContainer}
      style={activeFloor === floorData.floorId ? {} : { display: "none" }}
    >
      {/* <h3>{floorData.floorName}</h3> */}
      <Table
        className={styles.table}
        components={components}
        rowClassName={() => "editable-row"}
        size="small"
        dataSource={floorData.floorData}
        columns={columns as ColumnTypes}
        pagination={false}
      />
    </div>
  );
};
