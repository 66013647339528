import classNames from "classnames";
import { ICard } from "../../../../models/benchmark/shared/Card";
import styles from "./Card.module.scss";
import { useParams } from "react-router-dom";
const Card = ({
  selectable = true,
  data,
  isActive,
  compareActive,
  handleCardActivate,
  periodicsPrice,
}: {
  selectable?: boolean | undefined;
  data: ICard;
  isActive: boolean;
  compareActive: boolean;
  handleCardActivate: (cardId: string) => void;
  periodicsPrice?: number | undefined;
  
}) => {
  const { buildingId } = useParams();

  if (!compareActive && data.title === "Custom") return null;

  return (
    <div
      className={classNames(
        styles.cardContainer,
        cardStyle(data.title ?? "Premium"),
        buildingId !== "251" && isActive ? styles.active : ""
      )}
      onClick={() =>
        selectable ? handleCardActivate(data.title ?? "Premium") : null
      }
    >
      <h2>{data.title}</h2>
      {data.keypoint && (
        <div>
          <span
            className={styles.cardPrice}
          >{`${data.keypoint?.prefix}${data.keypoint?.main?.replace("{{totalCost}}", (periodicsPrice ?? 0).toLocaleString())}`}</span>
          <span className={styles.cardPeriod}>{data.keypoint?.sufix}</span>
        </div>
      )}
      {data.details?.map((detail, index) => (
        <span key={index} className={styles.cardPeriod}>
          {detail}
        </span>
      ))}
    </div>
  );
};

export default Card;

const cardStyle = (title: string) => {
  switch (title) {
    case "Premium":
      return styles.cardPremiumContainer;
    case "Optimal":
      return styles.cardOptimalContainer;
    case "Basic":
      return styles.cardBasicContainer;
    // case "Savings":
    //   return styles.cardPremiumContainer;
    case "Accumulated return":
      return styles.cardOptimalContainer;
    case "Manual totals":
      return styles.cardBasicContainer;
    case "Custom":
      return styles.cardCustomContainer;
    case "NHS Huddersfield":
      return styles.cardBasicContainer;
    case "2007":
      return styles.cardOptimalContainer;
    case "2021":
      return styles.cardPremiumContainer;
    case "Emissions Savings":
      return styles.cardPositiveContainer;
      case "Electricity Savings":
      return styles.cardPositiveContainer;
      case "Lamp Savings":
      return styles.cardPositiveContainer;
      case "Maintenance Savings":
      return styles.cardPositiveContainer;
      case "Total Savings":
      return styles.cardPositiveContainer;
      case "Payback":
      return styles.cardPositiveContainer;
      case "Cost of no Action":
      return styles.cardNegativeContainer;
      case "Payback (not incl. CRC)(months)":
        return styles.cardPositiveContainer;
      case "Payback (incl. CRC)(months)":
        return styles.cardPositiveContainer;
      case "Existing":
        return styles.cardNegativeContainer;
      case "Capital Investment Expenditure":
        return styles.cardNegativeContainer;
      case "Proposed":
        return styles.cardPositiveContainer;
      case "Savings":
        return styles.cardPositiveContainer;
    default:
      return styles.cardPremiumContainer;
  }
};
