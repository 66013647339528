import { Button, Divider, Drawer, Space, Spin, Switch, Tabs } from "antd";
import styles from "./DailyCleaning.module.scss";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { MoreOutlined } from "@ant-design/icons";

import Card from "../../shared/card/Card";
import { ICard } from "../../../../models/benchmark/shared/Card";
import { ITable } from "../../../../models/benchmark/shared/Table";

import { ROUTES } from "../../../../config/constants";
import ScreenTable from "../../shared/table/Table";
import { CloseOutlined } from "@ant-design/icons";
import { IDashboard } from "../../../../models/benchmark/Benchmark";
import Dashboard from "../../shared/dashboard/Dashboard";

const DailyCleaning = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("0");
  const [compareActive, setCompareActive] = useState<boolean>(true);
  const [detailsActive, setDetailsActive] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>("Premium");
  const [cardData, setCardData] = useState<ICard[]>();
  const [tablesData, setTablesData] = useState<ITable[]>();

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  const onCardClick = (cardId: string) => {
    setActiveOption(cardId);
  };

  const onDetailsClick = () => {
    setShowDetailsDrawer(true);
  };

  const onClose = () => {
    setShowDetailsDrawer(false);
  };
  return (
    <>
      <div className={styles.mobileLayout}>
        <Tabs
          className={styles.tabContainer}
          tabBarStyle={{ marginBottom: "0px" }}
          defaultActiveKey="1"
          type="card"
          activeKey={activeTabKey}
          onChange={(key: string) => setActiveTabKey(key)}
          items={benchmark?.screens
            ?.find(
              (screen) => screen.title === ROUTES.benchmark.dailyCleaning.name
            )
            ?.dashboards?.map((dashboard: IDashboard, index: number) => ({
              label: dashboard.title,
              key: index.toString(),
              children: (
                <Dashboard pending={pending} dashboardData={dashboard} />
              ),
            }))}
        />
      </div>
      <div
        className={styles.desktopLayout}
        style={{
          backgroundColor: "#fff",
          margin: "10px",
          minHeight: "calc(100% - 20px)",
        }}
      >
        <Tabs
          className={styles.tabContainer}
          tabBarStyle={{ marginBottom: "0px" }}
          defaultActiveKey="1"
          type="card"
          activeKey={activeTabKey}
          onChange={(key: string) => setActiveTabKey(key)}
          items={benchmark?.screens
            ?.find(
              (screen) => screen.title === ROUTES.benchmark.dailyCleaning.name
            )
            ?.dashboards?.map((dashboard: IDashboard, index: number) => ({
              label: dashboard.title ? dashboard.title : buildingId == "262" ? "Evening Cleaning" : "Daily Cleaning", //TODO make dynamic
              key: index.toString(),
              children: (
                <Dashboard
                  pending={pending}
                  dashboardData={dashboard}
                  dashboardFrRatingsData={benchmark.screens
                    ?.find(
                      (screen) =>
                        screen.title ===
                        `${ROUTES.benchmark.dailyCleaning.name} - FR RATINGS`
                    )
                    ?.dashboards?.find(
                      (dash) => dashboard.title === dash.title
                    )}
                />
              ),
            }))}
        />
      </div>
    </>
  );
};

export default DailyCleaning;
